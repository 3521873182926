.tableContainer {
    overflow-x: auto;
  }
  
  @media (max-width: 1200px) {
    .tableContainer {
      width: 100%;
      display: block;
    }
  }

  .tableCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  